import kebabCase from 'lodash/kebabCase'
import CommonEnvEnum from 'commonConfig/enumify/common/CommonEnvEnum'
import ModuleFederationServerPortEnum from 'commonConfig/enumify/moduleFederation/ModuleFederationServerPortEnum'
import { packageName2ExposeName } from '../../utils/packageNameUtils'

function remotesError() {
  const app = document.getElementById('app')
  const test = document.createTextNode(`网站维护中`)
  app.appendChild(test)
}

// eslint-disable-next-line no-unused-vars
function findConfigEnvByRemotesKey(key, config, allProjectKey) {
  function findKey(kebabCaseKey) {
    // console.log(kebabCaseKey)
    if (kebabCaseKey) {
      const index = allProjectKey.findIndex((item) => {
        return item === kebabCaseKey
      })
      if (index > -1) {
        return allProjectKey[index]
      } else {
        const tempKeys = kebabCaseKey.split('-')
        if (tempKeys.length) {
          tempKeys.pop()
          const newKey = tempKeys.join('-')
          return findKey(newKey)
        } else {
          return ''
        }
      }
    } else {
      return ''
    }
  }
  const kebabCaseKey = kebabCase(key)
  const projectKey = findKey(kebabCaseKey)
  if (projectKey) {
    return { projectKey, env: config[projectKey] }
  }
}

function developRemotes(localRemotes, devVersion, testVersion) {
  const result = {}
  const { config, server, port } = localRemotes
  const allProjectKey = []
  Object.keys(config).forEach((key) => {
    allProjectKey.push(key)
  })
  console.log(allProjectKey)
  for (let i = 0; i < allProjectKey.length; i++) {
    const packageKey = allProjectKey[i]
    const configLinkEnv = config[packageKey]
    const exposeKey = packageName2ExposeName(packageKey)
    const devLinkConfig = devVersion[packageKey]
    const testLinkConfig = testVersion[packageKey]
    // console.log('配置环境', packageKey, configLinkEnv, devLinkConfig, testLinkConfig)
    if (configLinkEnv === CommonEnvEnum.PRODUCTION_DEV.key && devLinkConfig && devLinkConfig.link) {
      const link = ModuleFederationServerPortEnum.getDevelopVarUrlBayPackageKey(
        packageKey,
        CommonEnvEnum.PRODUCTION_DEV.key
      )
      // console.log('链接到开发服', exposeKey, link, devLinkConfig)
      result[
        exposeKey
      ] = `${link}/web-v2/${CommonEnvEnum.PRODUCTION_DEV.key}-${packageKey}/${exposeKey}.${devLinkConfig.hash}.js`
    } else if (configLinkEnv === CommonEnvEnum.PRODUCTION_TEST.key && testLinkConfig && testLinkConfig.link) {
      const link = ModuleFederationServerPortEnum.getDevelopVarUrlBayPackageKey(
        packageKey,
        CommonEnvEnum.PRODUCTION_TEST.key
      )
      // console.log('链接到测试服', exposeKey, link, testLinkConfig)
      result[
        exposeKey
      ] = `${link}/web-v2/${CommonEnvEnum.PRODUCTION_TEST.key}-${packageKey}/${exposeKey}.${testLinkConfig.hash}.js`
      // console.log(linkConfig)
    } else {
      let projectServer = server['local'].url
      if (
        ![CommonEnvEnum.LOCAL.key, CommonEnvEnum.PRODUCTION_DEV.key, CommonEnvEnum.PRODUCTION_TEST.key].includes(
          configLinkEnv
        )
      ) {
        const link = ModuleFederationServerPortEnum.getDevelopVarUrlBayPackageKey(packageKey, configLinkEnv)
        if (link) projectServer = link
      }
      const projectPort = port[packageKey]
      // console.log('链接到开发环境的服务器', exposeKey, projectServer, projectPort)
      result[exposeKey] = `${projectServer}:${projectPort}/${exposeKey}.js`
    }
  }

  // console.log(result)
  return result
}

function prodRemotes(env, version) {
  return new Promise((resolve, reject) => {
    const result = {}
    Object.keys(version).forEach((key) => {
      const item = version[key]
      // const link = ModuleFederationServerPortEnum.getProdVarUrlByPackageKey(key, env)
      const exposeKey = packageName2ExposeName(key)
      if (
        [
          CommonEnvEnum.PRODUCTION_PREVIEW.key,
          CommonEnvEnum.PRODUCTION_STAGE.key,
          CommonEnvEnum.PRODUCTION_PROD.key,
        ].includes(env)
      ) {
        result[exposeKey] = `${item.link}/web-v2/${item.version}/${env}-${key}/${exposeKey}.${item.hash}.js`
      } else {
        result[exposeKey] = `${item.link}/web-v2/${env}-${key}/${exposeKey}.${item.hash}.js`
      }
    })
    console.log('result', result)
    resolve(result)
  })
}

export { remotesError, developRemotes, prodRemotes }
