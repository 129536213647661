import { OS_TYPE_ANDROID, OS_TYPE_IOS, OS_TYPE_PC } from './enum'
import BrowserType from 'commonConfig/enter/utils/browerType'
import CommonEnvEnum from 'commonConfig/enumify/common/CommonEnvEnum'
import { remotesError, developRemotes, prodRemotes } from 'commonConfig/enter/utils/remotsUtils'

export default function setConstant(processEnv, otherConstant = {}) {
  return new Promise((resolve, reject) => {
    const t = {
      ...processEnv,
      ...otherConstant,
    }
    window._JSB__APP = {}
    Object.keys(t).forEach((k) => {
      let key = k
      if ((k + '').includes('VUE_APP_')) {
        key = k.replace('VUE_APP_', '')
      }
      if (['VERSION', 'PARTNER_INFO', 'CLIENT_ADDRESS', 'SERVICE_PORT', 'VERSION_URL', 'LOCAL_REMOTES'].includes(key)) {
        if (t[k] && t[k] !== 'undefined' && t[k] !== 'null') {
          window._JSB__APP[key] = JSON.parse(t[k] + '')
        }
      } else {
        window._JSB__APP[key] = t[k]
      }
    })

    let os = OS_TYPE_PC

    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      os = OS_TYPE_IOS
    } else if (/(Android)/i.test(navigator.userAgent)) {
      os = OS_TYPE_ANDROID
    }

    window._JSB__APP.runtime = {
      isWechat: /MicroMessenger/i.test(window.navigator.userAgent) && (os === OS_TYPE_IOS || os === OS_TYPE_ANDROID),
      os,
    }
    window._JSB__APP.BROWSER_TYPE = BrowserType()

    console.log(window._JSB__APP)
    const env = window._JSB__APP.DEPLOY_ENV

    // const env = 'dev'

    console.log(env, ':', window._JSB__APP.LOCAL_REMOTES)
    const myHeaders = new Headers()
    myHeaders.append('pragma', 'no-cache')
    myHeaders.append('cache-control', 'no-cache')

    const config = {
      method: 'GET',
      headers: myHeaders,
    }
    _JSB__APP['REMOTES_ORDER'] = []
    if (env === CommonEnvEnum.LOCAL.key) {
      // 本地开发
      Promise.all([
        fetch(`${_JSB__APP.VERSION_URL.dev}/web-v2/dev-version.json`, config),
        // fetch(`${_JSB__APP.VERSION_URL.test}/web-v2/version.json`, config),
        fetch(`${_JSB__APP.VERSION_URL.test}/web-v2/test-version.json`, config),
      ]).then(([devRes, testRes]) => {
        if (devRes.ok && testRes.ok) {
          Promise.all([devRes.json(), testRes.json()]).then(([devVersion, testVersion]) => {
            // console.log('++++++', devVersion, testVersion)
            _JSB__APP['REMOTES_CONFIG'] = developRemotes(_JSB__APP.LOCAL_REMOTES, devVersion, testVersion)
            resolve(true)
          })
        }
      })
    } else {
      const flag = true
      const _JSB__APP_VERSION = JSON.parse(localStorage.getItem('_JSB__APP_VERSION'))
      /*if (_JSB__APP_VERSION) {
        const version_time = _JSB__APP_VERSION['version_time']
        if (version_time && version_time < +new Date() - 12 * 3600 * 1000) {
          flag = false
        }
      }*/
      if (flag) {
        fetch(`${_JSB__APP.VERSION_URL[env]}/web-v2/${env}-version.json`, config)
          .then((response) => {
            // console.log(response)
            if (response.ok) {
              response.json().then((version) => {
                // console.log('+++++', version)
                version['version_time'] = +new Date()
                localStorage.setItem('_JSB__APP_VERSION', JSON.stringify(version))
                prodRemotes(env, version).then((result) => {
                  _JSB__APP['REMOTES_CONFIG'] = result
                  resolve(true)
                })
              })
            } else {
              remotesError()
            }
          })
          .catch(() => {
            remotesError()
          })
      } else {
        prodRemotes(env, _JSB__APP_VERSION).then((result) => {
          _JSB__APP['REMOTES_CONFIG'] = result
          resolve(true)
        })
        fetch(`${_JSB__APP.VERSION_URL[env]}/web-v2/${env}-version.json`, config).then((response) => {
          // console.log(response)
          if (response.ok) {
            response.json().then((version) => {
              // console.log('+++++', version)
              version['version_time'] = +new Date()
              localStorage.setItem('_JSB__APP_VERSION', JSON.stringify(version))
            })
          }
        })
      }
    }
  })
}
